import { render, staticRenderFns } from "./protocol.vue?vue&type=template&id=1a91fd2f&scoped=true&"
import script from "./protocol.vue?vue&type=script&lang=js&"
export * from "./protocol.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a91fd2f",
  null
  
)

export default component.exports