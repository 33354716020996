import Vue from 'vue';
import BookService from './book.service';
import * as _ from 'lodash';
import download from 'downloadjs';
import ApiService from '@/common/api.service';
import moment from 'moment';
import bookService from './book.service';

const getDefaultControllerAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultObjectAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultRaiserAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultManufacturerAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultItem = () => ({
    inspection: [],
    selfInspection: [],
    _id: null,
    title: null,
    customer: null,
    typ: null,
    bookType: null,
    location: null,
    controller: null,
    controlleraddress: getDefaultControllerAddress(),
    object: null,
    objectnutzung: null,
    objectaddress: getDefaultObjectAddress(),
    raiser: null,
    raiseraddress: getDefaultRaiserAddress(),
    site: null,
    glasbocknr: '',
    lagerort: 'inFertigungsprozess',
    shortText: null,
    completedOn: null,
    enableFrom: null,
    nextInspectionDate: null,
    inspectionAmpel: null,
    nextInspectionDateSelf: null,
    inspectionSelfAmpel: null,
    firstservice: null,
    enableTo: null,
    visibleFrom: null,
    visibleTo: null,
    contactName: null,
    contactMail: null,
    contactTel: null,
    serviceinterval: 0,
    selfserviceinterval: 0,
    documents: [],
    misc: {},
    status: 1,
    manufacturerNo: null,
    orderNo: '',
    manufacturer: null,
    manufactureraddress: getDefaultManufacturerAddress(),
    active: true,
});

const getDefaultMeta = () => ({
    freeslots: 0,
    usedslots: 0,
    bookableInFuture: false,
    bookable: false,
});

const getDefaultStatuses = () => ({});

const getDefaultState = () => ({
    item: getDefaultItem(),
    meta: getDefaultMeta(),
    inspections: [],
    selfInspections: [],
    allMyInspections: [],
    imageUrl: null,
    list: [],
    statuses: getDefaultStatuses(),
    calculatedateInspection: null,
    calculatedateInspectionSelf: null,
    currentPage: 1,
    totalPages: 1,
});

const book = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
            let imageUrl = '';

            //TODO IMAGEURLS!!!!

            // _.forEach(data.documents, (doc) => {
            //     if (_.startsWith(_.toLower(doc.contentType),'image')) {
            //         imageUrl = ApiService.apiUrl() + 'blobs/' + data.documents[0] + '/document';
            //     }
            // });

            if (Array.isArray(data.documents) && data.documents.length > 0) {
                imageUrl = ApiService.apiUrl() + 'blobs/' + data.documents[0] + '/document';
            }
            Vue.set(state, 'imageUrl', imageUrl);
        },
        updateDocuments(state, data) {
            Vue.set(state.item, 'documents', data);
        },
        updateInspections(state, data) {
            Vue.set(state, 'inspections', data);
        },
        updateSelfInspections(state, data) {
            Vue.set(state, 'selfInspections', data);
        },
        updateAllMyInspections(state, data) {
            Vue.set(state, 'allMyInspections', data);
        },
        updateStatuses(state, data) {
            Vue.set(state, 'statuses', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
            Vue.set(state, 'inspections', []);
            Vue.set(state, 'selfInspections', []);
            Vue.set(state, 'myInspections', []);
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            delete client_data._id;
            delete client_data.__v;
            delete client_data.inspection;
            delete client_data.selfInspection;
            delete client_data.documents;
            const { data } = await BookService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            //todo besser object copy ?! -> not delete ??
            const { _id } = client_data;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.inspection;
            delete client_data.selfInspection;
            delete client_data.qrCodes;
            const { data } = await BookService.update(_id, client_data);
            context.commit('update', data);
            return data;
        },
        async fileUpload(context, { file, tenant, type }) {
            const { data } = await BookService.fileUpload(file, { tenant, type });
            // context.commit('update', data);
            return data;
        },
        async fileUploadPdf(context, { file, tenant }) {
            const { data } = await BookService.fileUploadPdf(file, { tenant });
            return data;
        },
        async calculatedateNextInspectionDate(context, params) {
            let book = params.book;
            let inspections = params.inspections;

            delete params.book;
            delete params.inspection;

            params.lastinspection = bookService.getLastInspection(inspections);

            if (params.lastinspection != null) {
                params.date = params.lastinspection;
            }
            const { data } = await BookService.calculatedate(params);
            book.nextInspectionDate = data.calcdate;
            context.commit('update', book);
            return data;
        },
        async calculatedateNextInspectionDateSelf(context, params) {
            let book = params.book;
            let selfInspections = params.selfInspections;
            let inspections = params.inspections;

            delete params.book;
            delete params.selfInspections;
            delete params.inspections;

            let lastSelfInspection = bookService.getLastInspection(selfInspections);
            let lastInspection = bookService.getLastInspection(inspections);
            if (lastInspection != null && moment(lastInspection).isAfter(moment(lastSelfInspection))) {
                params.lastinspection = lastInspection;
            } else {
                params.lastinspection = lastSelfInspection;
            }
            if (params.lastinspection != null) {
                params.date = params.lastinspection;
            }
            const { data } = await BookService.calculatedate(params);
            book.nextInspectionDateSelf = data.calcdate;
            context.commit('update', book);
            return data;
        },
        async get(context, id) {
            const { data } = await BookService.get(id);

            if (data.objectaddress == null) {
                data.objectaddress = getDefaultState().item.objectaddress;
            }

            if (data.raiseraddress == null) {
                data.raiseraddress = getDefaultState().item.raiseraddress;
            }

            if (data.controlleraddress == null) {
                data.controlleraddress = getDefaultState().item.controlleraddress;
            }

            if (data.manufactureraddress == null) {
                data.manufactureraddress = getDefaultState().item.manufactureraddress;
            }

            delete data.operator;
            context.commit('update', data);
            return data;
        },
        async getInspections(context, id) {
            const { data } = await BookService.getInspections(id);
            context.commit('updateInspections', data);
            return data;
        },
        async getSelfInspections(context, id) {
            const { data } = await BookService.getSelfInspections(id);
            context.commit('updateSelfInspections', data);
            return data;
        },
        async getStatuses(context) {
            const { data } = await BookService.getStatuses();
            context.commit('updateStatuses', data);
            return data;
        },
        async mappingBarcoode(context, { bookId, tenantId, qrCodeId }) {
            const { data } = await BookService.mappingBarcode(tenantId + '/' + bookId + '/' + qrCodeId);
            return data;
        },
        async generateBarcode(context, { id, tenant, qr }) {
            const { data } = await BookService.generateBarcode(tenant + '/' + id, { qr });
            return data;
        },
        async getBlob(context, id) {
            const { data } = await BookService.getBlob(id);
            data.realUrl = ApiService.apiUrl() + 'blobs/' + data._id + '/document';
            return data;
        },
        async deleteBlob(context, id) {
            return await BookService.deleteBlob(id);
        },
        async setBookTechAcceptor(context, client_data) {
            const { bookIds, techAcceptorId, tenantId, params, pdfassetId } = client_data;
            if (Array.isArray(bookIds)) {
                for (let i = 0; i < bookIds.length; i++) {
                    await BookService.setBookTechAcceptor(bookIds[i], techAcceptorId, tenantId, params, pdfassetId);
                }
            } else {
                await BookService.setBookTechAcceptor(bookIds, techAcceptorId, tenantId, params, pdfassetId);
            }
        },
        async move2folder(context, client_data) {
            const { bookId, folderId } = client_data;
            await BookService.moveBook2folder(bookId, folderId);
        },
        async downloadDocument(context, document) {
            const { data } = await BookService.downloadDocument(document._id);
            download(new Blob([data]), document.path.substring(document.path.lastIndexOf('/') + 1, document.path.length), document.contentType);
        },
        async delDocument(context, client_data) {
            const { bookId, docId } = client_data;
            const { data } = await BookService.delDocument(bookId, docId);
            context.commit('updateDocuments', data);
        },
        async getExportPdf(context, client_data) {
            // debugger;
            const { data } = await BookService.getExportPdf(client_data.bookId);
            const fileName = client_data.fileName ? client_data.fileName : 'Prüfbuch.pdf';

            download(new Blob([data]), fileName, 'application/pdf');
        },
        async getExportPdfC(context, client_data) {
            // debugger;
            const { data } = await BookService.getExportPdfC(client_data.bookId);
            const fileName = client_data.fileName ? client_data.fileName : 'Prüfbuch.pdf';

            download(new Blob([data]), fileName, 'application/pdf');
        },
        async getGutachtenPdf(context, client_data) {
            // debugger;
            const data = await BookService.getGutachtenPdf(client_data.bookId, client_data.gutachtenId);
            console.log('data', data);
            let serverFileName = '';
            if (data.headers['content-disposition']) {
                if (data.headers['content-disposition'].split('filename=')[1]) {
                    serverFileName = data.headers['content-disposition'].split('filename=')[1];
                    console.log('serverFileName', serverFileName);
                }
            }
            let contentType = 'application/pdf';
            if (data.headers['content-type']) {
                contentType = data.headers['content-type'];
            }
            let fileName = client_data.fileName ? (client_data.fileName += serverFileName) : serverFileName;

            if (fileName === '') {
                fileName = 'Prüfgutachten.pdf';
            }
            console.log('fileName', fileName);

            download(new Blob([data.data]), fileName, contentType);
        },
        async getBookFromQrCode(context, id) {
            const { data } = await BookService.getBookFromQrCode(id);
            return data;
        },
        async getAllMyInspections(context, client_data) {
            let filterByBook;
            if (client_data && client_data.book) {
                filterByBook = client_data.book;
            }
            let { searchBy, searchTerm } = client_data || {};
            const { data } = await BookService.getAllMyInspections(searchBy, searchTerm);

            if (filterByBook) {
                for (let i = 0; i < data.length; i++) {
                    let ainsp = data[i];
                    if (ainsp.bookId === filterByBook) {
                        context.commit('updateAllMyInspections', [ainsp]);
                    }
                }
            } else {
                context.commit('updateAllMyInspections', data);
            }

            return data;
        },
        async getAllInspectionsInProgress(context, client_data) {
            let { searchBy, searchTerm, tenantId } = client_data || {};
            const { data } = await BookService.getAllInspectionsInProgress(searchBy, searchTerm, tenantId);
            context.commit('updateAllMyInspections', data);
            return data;
        },
        async query(context, params) {
            const { data } = await BookService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await BookService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
        async deleteInspection(context, client_data) {
            const { bookId, inspectionId } = client_data;
            const slug = `${bookId}/inspection/${inspectionId}`;
            const { data } = await BookService.delete(slug);
            context.commit('updateInspections', data);
            return data;
        },
        async deleteSelfInspection(context, client_data) {
            const { bookId, selfInspectionId } = client_data;
            const slug = `${bookId}/selfinspection/${selfInspectionId}`;
            const { data } = await BookService.delete(slug);
            context.commit('updateSelfInspections', data);
            return data;
        },
        async addInspection(context, client_data) {
            const { book, account, child } = client_data;

            const inspection = {
                user_id: account._id,
                firstName: child.firstName,
                lastName: child.lastName,
                dob: child.dob,
                properties: child.properties,
            };

            const { data } = await BookService.addInspection(book._id, inspection);

            context.commit('update', data);
            return data;
        },
        async getEtikettenPdf(context, params) {
            const { data } = await BookService.getEtikettenPdf(params);
            download(new Blob([data]), 'Etiketten.pdf', 'application/pdf');
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        meta(state) {
            return state.meta;
        },
        inspections(state) {
            return state.inspections;
        },
        selfInspections(state) {
            return state.selfInspections;
        },
        myInspections(state) {
            return state.myInspections;
        },
        allMyInspections(state) {
            return state.allMyInspections;
        },
        imageUrl(state) {
            return state.imageUrl;
        },
        statuses(state) {
            return state.statuses;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

function getResText(code, doortype) {
    let schieberRes = {
        powslnt: 'SL NT',
        powslntfr: 'SL NT-FR',
        powecdrivet2: 'ECdrive T2 100mm',
        powecdrivet2fr: 'ECdrive T2-FR 100mm',
        powecdrive: 'ECdrive Haube 120mm',
        powecdrivefr: 'ECdrive-FR Haube 120mm',
        powplnt150: 'PL NT Haube 150mm',
        powplntfr150: 'PL NT-FR Haube 150mm',
        powecdrive150: 'ECdrive Haube 150mm',
        powecdrivefr150: 'ECdrive-FR Haube 150mm',
        powplnt200: 'PL NT Haube 200mm',
        powplntfr200: 'PL NT-FR Haube 200mm',
        powslt: 'SLT',
        powsltfr: 'SLT-FR',
        powslrc2: 'SL RC2',
        powslfrrc2: 'SL-FR RC2',
        powslbo: 'SL BO',
        powsf: 'SF',
        powsffr: 'SF-FR',
        powslt30storm: 'SL-T30 Sturm',
        powplt30storm: 'PL-T30 Sturm',
        powplt30pene: 'PL-T30 Peneder',
        powmisc: 'Sonstiges',
        ctrlelemgzwippas500: 'Drucktaster',
        ctrlelemgzwippe2: 'Drucktaster',
        ctrlelemgzled: 'LED Sensortaster',
        ctrlelemgzwippls: 'Drucktaster',
        ctrlelemgc306hand: 'berührungsloser Taster',
        ctrlelemgc306geze: 'berührungsloser Taster',
        ctrlelemgc306handd: 'berührungsloser Taster',
        ctrlelemgc306gezed: 'berührungsloser Taster',
        ctrlelemgc307hand: 'berührungsloser Taster',
        ctrlelemgc307geze: 'berührungsloser Taster',
        ctrlelemgc307handd: 'berührungsloser Taster',
        ctrlelemgc307gezed: 'berührungsloser Taster',
        ctrlelemgzledsensor: 'LED Sensortaster',
        ctrlelemgzledsensorglass: 'LED Sensortaster',
        ctrlelemgzareawhite: 'Flächentaster',
        ctrlelemgzareaniro: 'Flächentaster',
        ctrlelemgzarea990APstainless: 'Flächentaster',
        ctrlelemgzarea990UPstainless: 'Flächentaster',
        ctrlelemgzarea990APwhite: 'Flächentaster',
        ctrlelemgzarea990UPwhite: 'Flächentaster',
        ctrlelemgzareaeft: 'Flächentaster',
        ctrlelemgzfoot: 'Fu\u00dftaster',
        ctrlelemgzpull: 'Zugschalter',
        ctrlelemgzkey221ap: 'Schlüsseltaster',
        ctrlelemgzkey221up: 'Schlüsseltaster',
        ctrlelemgzkeyrast: 'Schlüsseltaster',
        ctrlelemgztoplockcti: 'Zahlencodeschloss',
        ctrlelemgztoplockctib: 'Zahlencodeschloss',
        ctrlelemgztoplockctsv: 'Zahlencodeschloss',
        ctrlelemgztoplockctsbv: 'Zahlencodeschloss',
        ctrlelemgzradar: 'Radarmelder',
        ctrlelemgzradar1: 'Radarmelder mit Lichtvorhang',
        ctrlelemgzradar2: 'Radarmelder mit Lichtvorhang',
        ctrlelemgzradarlicht: 'Lichtvorhang',
        ctrlelemgzradar3: 'Radarmelder mit Lichtvorhang',
        ctrlelemgzradar4: 'Radarmelder mit Lichtvorhang',
        ctrlelemgzradarlicht1: 'Lichtvorhang',
        ctrlelemgz304: 'Radarmelder',
        ctrlelemradarroof: '',
        ctrlelemradarroof2: '',
        ctrlelemradarrain: 'Regenabdeckung',
        ctrlelemradarroofwinkel: 'Deckenwinkel',
        ctrlelemradarroofwinkel339: 'Deckenwinkel',
        ctrlelemradarroofadapt: 'Rundbogenadapter',
        secsencable: 'Kabel\u00FCbergang verdeckt liegend bauseits!',
        secsenwet334: 'Regenabdeckung GC 334',
        secsenwet338: 'Regenabdeckung GC 338',
        secsenadaptor: 'Adapter f\u00FCr Sensorgest\u00E4nge EMD und TSA160NT',
        secsenfinger: 'Absicherung Fingerschutzrollo BG',
        secsenfingerband: 'Absicherung Fingerschutzrollo Bandseite',
        secsenfingerbandbau: 'bauseits',
        secsenfingerbandbaudwg: 'Baendertyp inkl. DWG Zeichnung erforderlich',
        secsensec342K: 'GC 342 Kit (2 Sensoren)',
        secsensec342L: 'GC 342 (Linkes Modul)',
        secsensec342R: 'GC 342 (Rechtes Modul)',
        secsensec334: 'Sicherheitssensor GC 334',
        secsensec338: 'Sensorleistenpaar GC 338',
        secsensec342: 'Flat Scan - BEA GC 342',
        secsenpow334: 'Ansteuersensor GC 334 BG',
        secsengcband: 'GC GR f\u00FCr GC338 (EMD/EMD-F) ',
        secsengcbg0: 'GC GR f\u00FCr GC338 (Powerturn) Bandseite',
        secsengcbg0gg: 'GC GR f\u00FCr GC338 (Powerturn) Bandgegenseite',
        secsengcbg: 'GC GR f\u00FCr GC338 (TSA 160 NT) ',
        secsengcbg2: 'GC GR f\u00FCr GC338 (TSA 160 NT Z) ',
        ptmschl: 'Schleuse',
        ptmrws: 'RWS- Ausf\u00FChrung',
        ptmll: 'LL- Ausf\u00FChrung',
        ptmduo: 'DUO- Ausf\u00FChrung',
        ptmbma: 'Anschluss BMA potentialfreier Schlie\u00dferkontakt Not\u00f6ffnung - KB',
        ptmbmabts24: 'T30 Schiebetueren BTS 24 - 24V DC',
        ptmwinter: 'Winterstellung - Reduzierung der \u00D6W um [mm]',
        ptmwinter2: 'Winterstellung bei FR T\u00FCren',
        ptmzsu: 'Umschaltung \u00FCber ZSU bei FR T\u00FCren',
        ptmnsk: 'Absicherung im Bereich der Nebenschlie\u00dfkante durch Kraftbegrenzung',
        ptnocap: 'Antrieb ohne Haube',
        ptrevb: 'Revisionsklappe bis 3 Meter',
        ptrevg: 'Revisionsklappe gr\u00f6\u00dfer 3 Meter',
        ptmwintermm: 'Redz. \u00D6W [mm]',
        print_ptmwintermm: 'Winterstellung FR - Reduzierung der \u00D6W um [mm]',
        plcelecto: 'Elek. Zahnriemenverriegelung',
        plcelecst: 'Elek. Stangenverriegelung',
        plcwoelec: 'ohne. elek. Verriegelung',
        plcpotfre: 'potentialfreier Kontakt',
        plcpotloc: 'Hakenriegelschlo\u00df Lock A',
        plcpotloc1: 'Lock M einseitig',
        plcpotloc2: 'Lock M beidseitig',
        smokedownfall: 'Sturzmelder GC 151 (RSZ6)',
        smokerm: 'RM- GC152 + Winkel',
        addconnzukoprovided: 'bauseitigen ZUKO',
        addconnzukopgeze: 'GEZE ZUKO',
        addconnbuildingctrlsys: 'Geb\u00E4udeleittechnik',
        addconntimeswitchprovided: 'Zeitschaltuhr bauseits',
        addconntimeswitchgeze: 'ZSU GEZE Theben',
        upsusv1000: 'USV-1000 (Steckdose notwendig)',
        upsusv700: 'USV-700 (Steckdose notwendig)',
        upsprovided: 'USV bauseits',
    };

    let dreherRes = {
        ctrlelemgzwippas500: 'Drucktaster',
        ctrlelemgzwippe2: 'Drucktaster',
        ctrlelemgzled: 'LED Sensortaster',
        ctrlelemgzwippls: 'Drucktaster',
        ctrlelemgc306hand: 'berührungsloser Taster',
        ctrlelemgc306geze: 'berührungsloser Taster',
        ctrlelemgc306handd: 'berührungsloser Taster',
        ctrlelemgc306gezed: 'berührungsloser Taster',
        ctrlelemgc306gezeap: '',
        ctrlelemgzledsensor: 'LED Sensortaster',
        ctrlelemgzledsensorglass: 'LED Sensortaster',
        ctrlelemgzareawhite: 'Flächentaster',
        ctrlelemgzareawhite2: 'Flächentaster',
        ctrlelemgzareaniro: 'Flächentaster',
        ctrlelemgzarea990APstainless: 'Flächentaster',
        ctrlelemgzarea990UPstainless: 'Flächentaster',
        ctrlelemgzarea990APwhite: 'Flächentaster',
        ctrlelemgzarea990UPwhite: 'Flächentaster',
        ctrlelemgzareaeft: 'Flächentaster',
        ctrlelemgzfoot: 'Fu\u00dftaster',
        ctrlelemgzpull: 'Zugschalter',
        ctrlelemgzkey221ap: 'Schlüsseltaster',
        ctrlelemgzkey221up: 'Schlüsseltaster',
        ctrlelemgzkeyrast: 'Schlüsseltaster',
        ctrlelemgztoplockcti: 'Zahlencode',
        ctrlelemgztoplockctib: 'Zahlencode',
        ctrlelemgztoplockctsv: 'Zahlencode',
        ctrlelemgztoplockctsbv: 'Zahlencode',
        ctrlelemgzradar: 'Radarmelder',
        ctrlelemradarroof: 'Deckeneinbausatz',
        ctrlelemradarroof2: 'Deckeneinbausatz',
        ctrlelemradarrain: 'Regenabdeckung',
        ctrlelemgzradar308: 'Radarmelder',
        ctrlelemradar308roof: 'Deckeneinbausatz',
        ctrlelemradar308angle: 'Montagewinkel',
        ctrlelemradar308rain: 'Wetterhaube',
        ctrlelemgzreed: 'Reedschalter',
        ctrlelemgzaddsdserv: '',
        ctrlelemgzblocksw: 'Riegelschaltkontakt',
        ctrlelemgzturnsw: 'Drehschaltkontakt',
        ctrlelemgzesbutton: 'Fusstaster',
        ctrlelemgzasbutton: 'Nottaste',
        ctrlelemgzmagic: 'berührungsloser Taster',
        ctrlelemgzpir: 'berührungsloser Taster',
        ctrlelemgzsonder: 'Sonderansteuerelement',
        ctrlelemfunk1k: 'Funk',
        ctrlelemfunk2k: 'Funk',
        ctrlelemfunk4k: 'Funk',
        ctrlelemfunkwtm: 'Funk',
        ctrlelemfunkwrm24: 'Funk',
        ctrlelemfunkwrm24b: 'Funk',
        ctrlelemfunkwrb5: 'Funk',
        secsensec334: 'Sicherheitssensor',
        secsensec338: 'Sensorleistenpaar',
        secsensec342: 'Flat Scan - BEA GC 342',
        secsenpow334: 'Ansteuersensor',
        secsengcband: 'GC GR f\u00FCr GC338 (EMD/EMD-F) ',
        secsengcbg0: 'GC GR f\u00FCr GC338 (Powerturn) Bandseite',
        secsengcbg0gg: 'GC GR f\u00FCr GC338 (Powerturn) Bandgegenseite',
        secsengcbg: 'GC GR f\u00FCr GC338 (TSA 160 NT) ',
        secsengcbg2: 'GC GR f\u00FCr GC338 (TSA 160 NT Z) ',
        doorhandlevertical: 'Griffstange senkrecht',
        doorhandleverticalsize1: '',
        doorhandleverticalsize2: '',
        secsencable: 'Kabel\u00FCbergang verdeckt liegend bauseits!',
        secsenwet334: 'Regenabdeckung',
        secsenwet338: 'Regenabdeckung',
        secsenadaptor: 'Adapter f\u00FCr Sensorgest\u00E4nge',
        secsenfinger: 'Absicherung Fingerschutzrollo BG',
        secsenpanik: 'Planet Montageprofil Panikbeschlag EV1',
        secsenfingerband: 'Absicherung Fingerschutzrollo Bandseite',
        secsenfingerbandbau: '',
        secsenfingerbandbaudwg: 'h',
        ptmpush: 'Push & Go',
        ptmloweng: 'Low Energy Modus (ECturn + EMD)',
        ptmservo: 'EMD-F im Servomodus',
        ptmwc: 'WC Steuerung (LED-Besetzt)',
        ptmemd: 'TS-Funktion f. EMD-F + Powerturn',
        ptmbrand1: 'Brandfallsteuerung f. Behinderte',
        ptmbrand2: 'Brandfallsteuerung f. Druckluftbel\u00FCftung',
        ptmpower1: 'T\u00FCrfreischaltung Powerturn',
        ptmpower2: 'Niedrigenergie Betrieb Powerturn',
        smokedownfall: 'Sturzmelder',
        smokerm: 'RM- GC152 + Winkel',
        addconn: 'Zus\u00E4tzliche Anbindung',
        addconnzukoprovided: 'bauseitigen ZUKO',
        addconnzukopgeze: 'GEZE ZUKO',
        addconnbuildingctrlsys: 'Geb\u00E4udeleittechnik',
        addconntimeswitchprovided: 'Zeitschaltuhr bauseits',
        addconntimeswitchgeze: 'ZSU GEZE Theben',
        rwsconntz320: 'TZ320',
        upsusv1000: 'USV-1000 (Steckdose notwendig)',
        upsprovided: 'USV bauseits',
        dorfixflu: 'Fl\u00FCgelausf\u00FChrung',
        dorfixfluvoll: 'Vollt\u00FCrblatt',
        dorfixflurahmen: 'Rahment\u00FCr mit Glasf\u00FCllung',
    };

    if (code == null) return '';

    if (doortype == null || doortype === 'dreher') {
        if (dreherRes[code] != null) {
            return dreherRes[code];
        }
    }

    if (doortype == null || doortype === 'schieber') {
        if (schieberRes[code] != null) {
            return schieberRes[code];
        }
    }
    console.log('code from getResText not found: ', code);
    console.warn('code from getResText not found: ', code);
    return code;
}

async function createBookFromAbkl(tenanturl, sheet, abkl, stamm, booktypeId, currFolder, bestellNo) {
    let newBook = getDefaultItem();

    newBook.tenant = tenanturl;
    newBook.folder = currFolder;
    newBook.orderNo = bestellNo;
    newBook.manufacturerNo = sheet.sn;

    newBook.misc.norm = '- ÖNORM EN 16005 idgF.\n';

    //TODO !!! das darf nicht hardcodiert werden - bzw da ist ja noch gar nicht klar wer der Prüfer sein wird!!
    newBook.controller = 'GLATZ TECHNIK GmbH';
    newBook.controlleraddress.street = 'Gewerbepark';
    newBook.controlleraddress.housenumber = '2';
    newBook.controlleraddress.zip = '4111';
    newBook.controlleraddress.location = 'Walding';
    newBook.controlleraddress.email = 'glatztechnik@glatztechnik.at';
    newBook.controlleraddress.tel = '+43 7234 8 23 19';
    // newBook.controlleraddress.country = 'Österreich';
    newBook.controlleraddress.homepage = 'https://www.glatztechnik.at/';

    newBook.manufacturer = 'GEZE Austria GmbH';
    newBook.manufactureraddress.street = 'Wiener Bundesstrasse';
    newBook.manufactureraddress.housenumber = '85';
    newBook.manufactureraddress.zip = '5300';
    newBook.manufactureraddress.location = 'Hallwang';
    newBook.manufactureraddress.email = 'austria.at@geze.com';
    newBook.manufactureraddress.tel = '+43-6225-87-180-0';
    newBook.manufactureraddress.homepage = 'https://www.geze.at/';

    newBook.title = `${abkl.montageposobj} - ${abkl.montagepos}`;
    newBook.typ = 'Autom. Türe GEZE AT';
    newBook.location = `${abkl.montagepos}`;
    newBook.object = stamm != null ? stamm.obj : abkl.montageposobj;
    newBook.site = abkl.montagepos;
    newBook.shortText = `${abkl.montageposobj} - ${abkl.montagepos}`;
    newBook.lagerort = 'inFertigungsprozess';
    newBook.glasbocknr = '';

    if (stamm != null) {
        newBook.objectaddress.street = stamm.objaddr;
        //newBook.objectaddress.housenumber = stamm.objaddr;
        newBook.objectaddress.zip = stamm.objaddrplz;
        newBook.objectaddress.location = stamm.objaddrcity;
        // newBook.objectaddress.tel = stamm.objaddr;
        // newBook.objectaddress.email = stamm.objaddr;
        // newBook.objectaddress.homepage = stamm.objaddr;
        // newBook.objectaddress.country = stamm.objaddr;

        newBook.contactName = stamm.cust_contact;
        newBook.contactTel = stamm.cust_contacttel;
        newBook.contactMail = stamm.cust_contactmail;

        newBook.raiser = stamm.customer;
        newBook.raiseraddress.street = stamm.custaddr;
        newBook.raiseraddress.zip = stamm.custaddrplz;
        newBook.raiseraddress.location = stamm.custaddrcity;
        newBook.raiseraddress.email = stamm.cust_contactmail;
        newBook.raiseraddress.tel = stamm.cust_contacttel;

        if (sheet.blatttyp === 'dreher') {
            newBook.misc.manufacturerdoor = stamm.customer;
        } else {
            newBook.misc.manufacturerdoor = 'GEZE Austria GmbH';
        }
    }

    newBook.misc.blatttyp = sheet.blatttyp;

    if (sheet.blatttyp === 'dreher') {
        newBook.misc.width = abkl.wingcount === 'wingcount1' ? abkl.wingwidth : abkl.bandlength;
        newBook.misc.height = abkl.wingheight;
        newBook.misc.typedoor = getResText(abkl.doorfixtureflu, 'dreher');
    } else {
        newBook.misc.width = abkl.openwidth;
        newBook.misc.height = abkl.openheight;
    }

    let wingcount = 0;
    if (typeof abkl.wingcount === 'string' || abkl.wingcount instanceof String) {
        if (abkl.wingcount.length > 0) {
            wingcount = abkl.wingcount.slice(-1);
        }
    }
    // newBook.misc.yearOfConstruction = abkl.yearOfConstruction;
    newBook.misc.doorDesign = `${wingcount} - flügelig`;
    let typeOfConstruction;

    newBook.misc.drivePowerOperated = 'elektrisch';

    let misctype;
    let bedInnen = '';
    let bedAussen = '';
    let bedInnenText;
    let bedAussenText;
    let sicherheitseinrichtung = '';
    let additionalInfo = '';

    if (sheet.blatttyp === 'dreher') {
        misctype = getResText(abkl.powertype, 'dreher');

        if (abkl.powertypeoptists) {
            misctype += ' IS/TS';
        } else if (abkl.powertypeoptis) {
            misctype += ' IS';
        }

        bedInnenText = 'Bedienung Bandseite';
        bedAussenText = 'Bedienung Gegenseite';

        if (abkl.ctrlelem1 != null) {
            bedInnen += getResText(abkl.ctrlelem1, 'dreher');
        }
        if (abkl.ctrlelem2 != null) {
            bedInnen += ' ' + getResText(abkl.ctrlelem2, 'dreher');
        }
        if (abkl.ctrlelem3 != null) {
            bedInnen += ' ' + getResText(abkl.ctrlelem3, 'dreher');
        }

        if (abkl.ctrlelemgg1 != null) {
            bedAussen += getResText(abkl.ctrlelemgg1, 'dreher');
        }
        if (abkl.ctrlelemgg2 != null) {
            bedAussen += ' ' + getResText(abkl.ctrlelemgg2, 'dreher');
        }
        if (abkl.ctrlelemgg3 != null) {
            bedAussen += ' ' + getResText(abkl.ctrlelemgg3, 'dreher');
        }

        let secsens = [
            'secsensec342K',
            'secsensec342L',
            'secsensec342R',
            'secsensec338',
            'secsensec334',
            'secsenpow334',
            'secsengcband',
            'secsengcbg0',
            'secsengcbg0gg',
            'secsengcbg',
            'secsengcbg2',
            'secsenfinger',
            'secsenfingerband',
        ];

        for (let j = 0; j < secsens.length; j++) {
            let aSec = secsens[j];
            if (abkl[aSec]) {
                let translated = getResText(aSec, 'dreher');

                if (translated === aSec) {
                    translated = getResText(aSec, 'schieber');
                }
                sicherheitseinrichtung += translated + ', ';
            }
        }

        let sonders = [
            'ptmpush',
            'ptmloweng',
            'ptmservo',
            'ptmwc',
            'ptmemd',
            'ptmbrand1',
            'ptmbrand2',
            'ptmpower1',
            'ptmpower2',
            'smokedownfall',
            'smokerm',
            'addconnzukoprovided',
            'addconnzukopgeze',
            'addconnbuildingctrlsys',
            'addconntimeswitchprovided',
            'addconntimeswitchgeze',
            'rwsconntz320',
            'upsusv1000',
            'upsprovided',
        ];

        for (let j = 0; j < sonders.length; j++) {
            let aSec = sonders[j];
            if (abkl[aSec]) {
                additionalInfo += getResText(aSec, 'dreher') + ', ';
            }
        }

        if (abkl.addconnproggezesonst) {
            additionalInfo += abkl.addconnproggezesonst + ', ';
        }

        if (abkl.progswitch === 'progswint') {
            additionalInfo += 'Interner PS, ';
        } else {
            additionalInfo += 'PS: ' + abkl.progswposition;
        }
    } else {
        misctype = getResText(abkl.powertype, 'schieber');

        if (('' + abkl.powertype).includes('fr')) {
            newBook.misc.fluchtweg = true;
            newBook.misc.norm =
                '- § 20 (4) AstVO, BGBl. 368/98 idgF.\n' +
                '- ÖNORM EN 16005 idgF.\n' +
                '- Richtlinie über automatische Schiebetüren und Rettungswege (AutSchR 12/97)';
        }

        bedInnenText = 'Bedienung Antriebsseite';
        bedAussenText = 'Bedienung Gegenseite';

        if (abkl.ctrlelem1 != null) {
            bedInnen += getResText(abkl.ctrlelem1, 'schieber');
        }
        if (abkl.ctrlelem2 != null) {
            bedInnen += ' ' + getResText(abkl.ctrlelem2, 'schieber');
        }
        if (abkl.ctrlelem4 != null) {
            bedInnen += ' ' + getResText(abkl.ctrlelem4, 'schieber');
        }

        if (abkl.ctrlelemgg1 != null) {
            bedAussen += getResText(abkl.ctrlelemgg1, 'schieber');
        }
        if (abkl.ctrlelemgg2 != null) {
            bedAussen += ' ' + getResText(abkl.ctrlelemgg2, 'schieber');
        }
        if (abkl.ctrlelemgg3 != null) {
            bedAussen += ' ' + getResText(abkl.ctrlelemgg3, 'schieber');
        }

        sicherheitseinrichtung = 'Anwesenheitssensor innen + außen, ';

        let secsens = [
            'secsensec342K',
            'secsensec342L',
            'secsensec342R',
            'secsensec338',
            'secsensec334',
            'secsenpow334',
            'secsengcband',
            'secsengcbg0',
            'secsengcbg0gg',
            'secsengcbg',
            'secsengcbg2',
            'secsenfinger',
            'secsenfingerband',
            'ctrlelem4',
        ];

        for (let j = 0; j < secsens.length; j++) {
            let aSec = secsens[j];
            if (abkl[aSec]) {
                sicherheitseinrichtung += getResText(aSec, 'schieber') + ', ';
            }
        }

        if (abkl.se0secwing || abkl.se0secwing1) {
            sicherheitseinrichtung += 'Sicherungsflügel, ';
        }

        if (abkl.securitywingwidthlend || abkl.securitywingwidthlsonder) {
            sicherheitseinrichtung += 'Schutzflügel links, ';
        }

        if (abkl.securitywingwidthrend || abkl.securitywingwidthrsonder) {
            sicherheitseinrichtung += 'Schutzflügel rechts, ';
        }

        let sonders = [
            'ptmschl',
            'ptmrws',
            'ptmll',
            'ptmduo',
            'ptmbma',
            'ptmbmabts24',
            'ptmwinter',
            'ptmzsu',
            'ptmnsk',
            'plcelecto',
            'plcelecst',
            'plcwoelec',
            'plcpotfre',
            'plcpotloc',
            'plcpotloc1',
            'plcpotloc2',
            'smokedownfall',
            'smokerm',
            'addconnzukoprovided',
            'addconnzukopgeze',
            'addconnbuildingctrlsys',
            'addconntimeswitchprovided',
            'addconntimeswitchgeze',
            'upsusv1000',
            'upsusv700',
            'upsprovided',
        ];

        for (let j = 0; j < sonders.length; j++) {
            let aSec = sonders[j];
            if (abkl[aSec]) {
                if (aSec === 'ptmwinter') {
                    additionalInfo += getResText(aSec, 'schieber') + ': ' + abkl.ptmwintermm + ', ';
                } else {
                    additionalInfo += getResText(aSec, 'schieber') + ', ';
                }
            }
        }

        if (abkl.progswitch) {
            additionalInfo += 'Position Programmschalter: ' + abkl.progswposition + ',';
        }

        if (abkl.addconnproggezesonst) {
            additionalInfo += abkl.addconnproggezesonst + ',';
        }
    }
    newBook.misc.type = misctype;

    let schiebtype = newBook.misc.fluchtweg ? 'Fluchtwegschiebetür (redundant)' : 'Schiebetür';

    typeOfConstruction = `Kraftbetätigte ${sheet.blatttyp === 'dreher' ? 'Drehflügeltür' : schiebtype}`;
    newBook.misc.typeOfConstruction = typeOfConstruction; //+ `${wingcount} - flügelig`;

    newBook.misc.bedienungInnen = bedInnen;
    newBook.misc.bedienungAussen = bedAussen;
    newBook.misc.bedienungInnenText = bedInnenText;
    newBook.misc.bedienungAussenText = bedAussenText;
    newBook.misc.sicherheitseinrichtung = 'Begrenzung der Flügelkräfte und Geschwindigkeitsdämpfung, Notöffnung von Hand, ' + sicherheitseinrichtung;
    newBook.misc.additionalInfo = additionalInfo;

    newBook.misc.yearOfConstruction = moment().format('MM-YYYY');

    newBook.misc.schliesskraft = 'einstellbar';
    newBook.misc.steuersystemOeffnen = 'mit Selbsthaltung';
    newBook.misc.steuersystemSchliessen = 'mit Selbsthaltung / automatisch';

    newBook.misc.antriebsspannung = '230 V';
    newBook.misc.steuerspannung = '24 V';
    // newBook.misc.leistung = abkl.leistung;

    newBook.misc.misc = abkl;

    newBook.bookType = booktypeId;

    delete newBook._id;
    delete newBook.__v;
    delete newBook.inspection;
    delete newBook.selfInspection;
    delete newBook.documents;
    const { data } = await BookService.create(newBook);
    return data;
}

export { createBookFromAbkl };
export default book;
