/**
 * Every route becomes a chunk, loaded only when used.
 * Reduces size of initial App load.
 */
const routes = [
    {
        name: 'login',
        path: '/admin/login',
        component: () => import('@/app/login/login.vue'),
        title: 'Login',
        isPublic: true,
    },
    {
        name: 'validate2fa',
        path: '/admin/validate2fa',
        component: () => import('@/app/login/validate2fa.vue'),
        title: '2-Faktor-Auth',
        isPublic: true,
    },
    {
        name: 'disable2fa',
        path: '/disable2fa',
        component: () => import('@/app/account/settings/disable2fa.vue'),
        title: 'Disable 2-Faktor-Auth',
        isPublic: true,
    },
    {
        name: 'confirmation',
        path: '/confirmation',
        component: () => import(/* webpackChunkName: "confirmation" */ '@/app/account/settings/confirmation.vue'),
        title: 'Confirmation',
        isPublic: true,
    },
    {
        name: 'home',
        path: '/',
        component: () => import(/* webpackChunkName: "dashboard" */ '@/app/home/home.vue'),
        title: 'Home',
        isPublic: true,
    },
    {
        name: 'passwordreset',
        path: '/admin/passwordreset',
        component: () => import(/* webpackChunkName: "passwordreset" */ '@/app/account/settings/passwordreset.vue'),
        title: 'Account',
        isPublic: true,
    },
    {
        name: 'passwordresetfinish',
        path: '/admin/reset/finish',
        component: () => import(/* webpackChunkName: "passwordresetfinish" */ '@/app/account/settings/passwordreset_finish.vue'),
        title: 'Account',
        isPublic: true,
    },
    {
        name: 'useradministration_list',
        path: '/admin/useradministration',
        component: () => import('@/app/administration/useradministration_list.vue'),
        title: 'Useradministation',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'admin_dashboard',
        path: '/admin/dashboard',
        component: () => import('@/app/administration/admin_dashboard.vue'),
        title: 'Dashboard',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'useradministration',
        path: '/admin/useradministration/:slug',
        component: () => import('@/app/administration/useradministration.vue'),
        title: 'Useradministation',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'settings',
        path: '/admin/settings/:slug',
        component: () => import(/* webpackChunkName: "settings" */ '@/app/account/settings/settings.vue'),
        title: 'Settings',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
            'ROLE_FERTIGUNG',
            'ROLE_LEITER_FERTIGUNG',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'tenant_list',
        path: '/admin/tenant_list/',
        component: () => import(/* webpackChunkName: "tenant_list" */ '@/app/tenant/tenant_list.vue'),
        title: 'Mandanten',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'LIST_ADMIN_ROLE', 'STANDARD_ROLE', 'ACCEPTOR_ROLE', 'ACCEPTOR_LITE_ROLE', 'PROJECT_ROLE', 'PROJECT_ONLY_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'tenant',
        path: '/admin/tenant/:slug',
        component: () => import(/* webpackChunkName: "tenant" */ '@/app/tenant/tenant.vue'),
        title: 'Mandant',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'LIST_ADMIN_ROLE', 'STANDARD_ROLE', 'ACCEPTOR_ROLE', 'ACCEPTOR_LITE_ROLE', 'PROJECT_ROLE', 'PROJECT_ONLY_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'statistik_gutachten',
        path: '/statistik_gutachten/:slug',
        component: () => import(/* webpackChunkName: "tenant" */ '@/app/inspection/inspection_statistik_gutachten.vue'),
        title: 'Mandant',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'booktype_list',
        path: '/admin/booktype_list',
        component: () => import(/* webpackChunkName: "booktype_list" */ '@/app/booktype/booktype_list.vue'),
        title: 'Prüfbuchtypen',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'booktype',
        path: '/admin/booktype/:slug',
        component: () => import(/* webpackChunkName: "booktype" */ '@/app/booktype/booktype.vue'),
        title: 'Prüfbuchtyp',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'changepassword',
        path: '/admin/changepassword/:slug',
        component: () => import(/* webpackChunkName: "changepassword" */ '@/app/account/settings/password.vue'),
        title: 'Passwort',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
            'ROLE_FERTIGUNG',
            'ROLE_LEITER_FERTIGUNG',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'list_allmyinspections',
        path: '/allmyinspections',
        component: () => import(/* webpackChunkName: "list_edit" */ '@/app/book/book_allmyinspections.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'LIST_ADMIN_ROLE', 'STANDARD_ROLE', 'ACCEPTOR_ROLE', 'ACCEPTOR_LITE_ROLE', 'PROJECT_ROLE', 'PROJECT_ONLY_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'list_orderprocessing',
        path: '/orderprocessinglist',
        component: () => import(/* webpackChunkName: "list_edit" */ '@/app/orderprocessing/orderprocessing_list.vue'),
        title: 'auftragsbearbeitung',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_SERVICE_TECHNIKER',
            'ROLE_FERTIGUNG',
            'ROLE_LEITER_FERTIGUNG',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'orderprocessing',
        path: '/orderprocessing/:slug?',
        component: () => import(/* webpackChunkName: "list_edit" */ '@/app/orderprocessing/orderprocessing.vue'),
        title: 'auftrag',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_SERVICE_TECHNIKER',
            'ROLE_FERTIGUNG',
            'ROLE_LEITER_FERTIGUNG',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'opevaluation',
        path: '/opevaluation',
        component: () => import(/* webpackChunkName: "list_edit" */ '@/app/opevaluation/opevaluation.vue'),
        title: 'auswertung',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'LIST_ADMIN_ROLE', 'STANDARD_ROLE', 'ACCEPTOR_ROLE', 'ACCEPTOR_LITE_ROLE', 'PROJECT_ROLE', 'PROJECT_ONLY_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'list_allinspectionsinprogress',
        path: '/allinspectionsinprogress/:tenant',
        component: () => import(/* webpackChunkName: "list_edit" */ '@/app/book/book_allinspectionsinprogress.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'LIST_ADMIN_ROLE', 'STANDARD_ROLE', 'ACCEPTOR_ROLE', 'ACCEPTOR_LITE_ROLE', 'PROJECT_ROLE', 'PROJECT_ONLY_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'register',
        path: '/register',
        component: () => import(/* webpackChunkName: "register" */ '@/app/account/settings/register.vue'),
        title: 'Registrierung',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE'],
        allowedCustomerRoles: [],
    },
    {
        name: 'tenantStart',
        path: '/map/:slug/',
        component: () => import(/* webpackChunkName: "tenantStart" */ '@/app/tenant/tenant_start.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'book_list',
        path: '/book/:tenant/:slug',
        component: () => import(/* webpackChunkName: "book" */ '@/app/book/book.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'book_new',
        path: '/book/:tenant/new/:folder',
        component: () => import(/* webpackChunkName: "book_edit" */ '@/app/book/book_edit.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'book_edit',
        path: '/book/:tenant/:slug/edit',
        component: () => import(/* webpackChunkName: "book_edit" */ '@/app/book/book_edit.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'qrcode',
        path: '/qrcode/:slug',
        component: () => import(/* webpackChunkName: "qrcode" */ '@/app/book/qrcode.vue'),
        title: 'pruefbuch',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: [],
    },
    {
        name: 'inspection_pruefgutachten',
        path: '/book/:tenant/:book/inspection_pruefgutachten/:slug',
        component: () => import(/* webpackChunkName: "inspection_pruefgutachten" */ '@/app/inspection/inspection_pruefgutachten'),
        title: 'Prüfgutachten',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_CONTROL'],
    },
    {
        name: 'inspection_pruefgutachten_upload_existing',
        path: '/book/:tenant/:book/inspection_pruefgutachten_upload_existing/:slug',
        component: () => import('@/app/inspection/inspection_pruefgutachten_upload_existing.vue'),
        title: 'Prüfgutachten',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_CONTROL'],
    },
    {
        name: 'inspection_wartung',
        path: '/book/:tenant/:book/inspection_wartung/:slug',
        component: () => import(/* webpackChunkName: "inspection_wartung" */ '@/app/inspection/inspection_wartung'),
        title: 'Wartung',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'inspection',
        path: '/admin/inspection/:slug',
        component: () => import(/* webpackChunkName: "inspection" */ '@/app/inspection/inspection.vue'),
        title: 'Inspection',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'selfinspection',
        path: '/admin/selfinspection/:slug',
        component: () => import(/* webpackChunkName: "inspection" */ '@/app/inspection/selfinspection.vue'),
        title: 'Selfinspection',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_DEFAULT', 'ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'customerlocation_list',
        path: '/customerlocationlist',
        component: () => import(/* webpackChunkName: "customerlocation_list" */ '@/app/customerlocation/customerlocation_list.vue'),
        title: 'Filialen',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'qrcode_scan',
        path: '/qrcode_scan',
        component: () => import('@/app/book/qrcode_scan.vue'),
        title: 'QRCode',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'ROLE_FILIALE', 'ROLE_SERVICE_TECHNIKER'],
        allowedCustomerRoles: ['ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'customer_location',
        path: '/customerlocation/:slug?',
        component: () => import(/* webpackChunkName: "customerlocation" */ '@/app/customerlocation/customerlocation.vue'),
        title: 'Kundenstandort',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'LIST_ADMIN_ROLE',
            'STANDARD_ROLE',
            'ACCEPTOR_ROLE',
            'ACCEPTOR_LITE_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'ROLE_LAGER',
            'ROLE_FILIALE',
            'ROLE_SERVICE_TECHNIKER',
        ],
        allowedCustomerRoles: ['ROLE_REGIONAL_MANAGER', 'ROLE_CONTROL'],
    },
    {
        name: 'costlist',
        path: '/costlist',
        component: () => import(/* webpackChunkName: "customerlocation_list" */ '@/app/costlist/costlist.vue'),
        title: 'Kostenliste',
        isPublic: false,
        allowedRoles: ['ADMIN_ROLE', 'ROLE_LEITER_FERTIGUNG'],
        allowedCustomerRoles: [],
    },
    {
        name: 'capacities',
        path: '/capacities',
        component: () => import(/* webpackChunkName: "customerlocation_list" */ '@/app/capacities/capacities.vue'),
        title: 'Kapazitätenplanung',
        isPublic: false,
        allowedRoles: [
            'ADMIN_ROLE',
            'ROLE_FERTIGUNG',
            'ROLE_LEITER_FERTIGUNG',
            'ROLE_LAGER',
            'ROLE_SERVICE_TECHNIKER',
            'STANDARD_ROLE',
            'PROJECT_ROLE',
            'PROJECT_ONLY_ROLE',
            'LIST_ADMIN_ROLE',
        ],
        allowedCustomerRoles: [],
    },
];

export default routes;
