import Vue from 'vue';
import CapacitiesService from '@/app/capacities/capacities.service';

const getDefaultItem = () => ({
    date: null,
    totalEmployeeTime: 0,
    totalOrdersTime: 0,
    orderTimeList: [],
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const capacities = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await CapacitiesService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await CapacitiesService.update(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await CapacitiesService.query(params);
            context.commit('updateList', data);
            return data;
        },
        async getRequiredTimeFromOrderList(context, params) {
            const { data } = await CapacitiesService.getRequiredTimeFromOrderList(params);
            return data;
        },
        async getRequiredTimeFromOrder(context, params) {
            const { data } = await CapacitiesService.getRequiredTimeFromOrder(params);
            return data;
        },
        async getWeeklyEmployeeTime(context, params) {
            const { data } = await CapacitiesService.getWeeklyEmployeeTime(params);
            return data;
        },
        async getCapacityForWeekFromDate(context, params) {
            const { data } = await CapacitiesService.getCapacityForWeekFromDate(params);
            context.commit('updateList', data);
            return data;
        },
        async getMonthlyEmployeeTime(context, params) {
            const { data } = await CapacitiesService.getMonthlyEmployeeTime(params);
            return data;
        },
        async getCapacityForMonthFromDate(context, params) {
            const { data } = await CapacitiesService.getCapacityForMonthFromDate(params);
            context.commit('updateList', data);
            return data;
        },
        async getTypenschildExcelData(context, params) {
            const { data } = await CapacitiesService.getTypenschildExcelData(params);
            return data;
        },
        async saveCapacityByOrderId(context, params) {
            const { data } = await CapacitiesService.saveCapacityByOrderId(params);
            return data;
        },
        async finishTimeslot(context, params) {
            const { data } = await CapacitiesService.finishTimeslot(params);
            return data;
        },
        async shiftTimeslot(context, params) {
            const { data } = await CapacitiesService.shiftTimeslot(params);
            return data;
        },
        async saveNewTimeslot(context, params) {
            const { data } = await CapacitiesService.saveNewTimeslot(params);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
    },
};

export { getDefaultItem };
export default capacities;
